<template>
  <v-main>
    <v-container>
      <div class="content content-text" v-if="locale == 'UA'">
        <h2>Умови повернення автобусних квитків</h2>
        <p>
          Можливістю повернення автобусного квитка користуються пасажири, які по
          якійсь причині не можуть скористатися ним. Після повернення, вони
          забирають частину вартості. Кошти повертаються протягом деякого часу
          на платіжну систему, з якої була здійснена оплата квитка.
        </p>
        <p>Повернення квитків на автобус, розрахунок суми повернення:</p>
        <ul>
          <li>більше 48 год - 90%</li>
          <li>24-48 год - 80%</li>
          <li>12-24 год - 50%</li>
          <li>2-12 год - 20%</li>
          <li>менше 2 год - не повертається</li>
        </ul>
        <p>
          В разі хвороби та нещасного випадку, протягом 72 годин після
          відправлення автобуса, можна повернути квиток, при наявності
          лікарняної довідки. Таким чином сума, потрачена на купівлю квитка
          повертається пасажирові, або ж переоформлюється новий, безкоштовний
          квиток на інший автобус. <br />Якщо людина не в змозі самостійно
          здійснити повернення, то дія проводиться через третю особу, не
          втрачаючи часу.
        </p>
        <h4>Обмін квитка на автобус</h4>
        <p>
          Обміняти свій квиток можливо лише за телефоном на гарячу лінію. Таким
          чином ви повертаєте його і отримуєте новий білет на інший рейс. Сума
          за повернений віддається вся, не враховуючи комісію. На електронному
          квитку міститься код замовлення, який ви маєте вказати диспетчеру. При
          заміні, диспетчер може потребувати документ, підтверджуючий оплату
          квитка квитку.
        </p>
      </div>
      <div class="content content-text" v-if="locale == 'EN'">
        <h2>Terms and conditions for returning bus tickets</h2>
        <p>
          The possibility of returning a bus ticket is used by passengers who
          for some reason they cannot use it. After returning, they
          take part of the cost. Funds are returned within some time
          to the payment system from which the ticket was paid.
        </p>
        <p>Return of bus tickets, calculation of the refund amount:</p>
        <ul>
          <li>more than 48 hours - 90%</li>
          <li>24-48 hours - 80%</li>
          <li>12-24 hours - 50%</li>
          <li>2-12 hours - 20%</li>
          <li>less than 2 hours - non-refundable</li>
        </ul>
        <p>
          In case of illness and accident, within 72 hours after
          departure of the bus, you can return the ticket, if available
          hospital certificate. Thus, the amount spent on buying a ticket
          it is returned to the passenger, or a new one is reissued, free of charge
          a ticket for another bus. <br />If a person is not able to independently
          make a return, then the action is carried out through a third party, no
          wasting time
        </p>
        <h4>Bus ticket exchange</h4>
        <p>
          You can exchange your ticket only by calling the hotline. Such
          so you return it and get a new ticket for another flight. Sum
          for the return, everything is given, excluding the commission. On electronic
          the ticket contains the order code, which you must indicate to the dispatcher. At
          replacement, the dispatcher may require a document confirming payment
          ticket ticket
        </p>
      </div>
      <div class="content content-text" v-if="locale == 'PL'">
        <h2>Warunki zwrotu biletów autobusowych</h2>
        <p>
          Z możliwości zwrotu biletu autobusowego korzystają pasażerowie, którzy
          z jakiegoś powodu nie mogą z niego skorzystać. Po powrocie oni
          wziąć udział w kosztach. Środki zwracane są po pewnym czasie
          do systemu płatności, z którego bilet został opłacony.
        </p>
        <p>Zwrot biletów autobusowych, obliczenie kwoty zwrotu:</p>
        <ul>
          <li>ponad 48 godzin – 90%</li>
          <li>24–48 godzin — 80%</li>
          <li>12–24 godziny — 50%</li>
          <li>2-12 godzin - 20%</li>
          <li>mniej niż 2 godziny – nie podlega zwrotowi</li>
        </ul>
        <p>
          W przypadku choroby i wypadku w ciągu 72 godzin
          odjazdu autobusu, możesz zwrócić bilet, jeśli jest dostępny
          zaświadczenie ze szpitala. Zatem kwota wydana na zakup biletu
          zostaje on bezpłatnie zwrócony pasażerowi lub wydany ponownie
          bilet na inny autobus. <br />Jeśli dana osoba nie jest w stanie samodzielnie
          dokonać zwrotu, wówczas czynność odbywa się za pośrednictwem osoby trzeciej, nie
          marnować czas
        </p>
        <h4>Wymiana biletów autobusowych</h4>
        <p>
          Wymiany biletu możesz dokonać wyłącznie dzwoniąc na infolinię. Taki
          więc go zwrócisz i otrzymasz nowy bilet na inny lot. Suma
          w zamian podane jest wszystko, łącznie z prowizją. Na elektronicznym
          na bilecie znajduje się kod zamówienia, który należy wskazać dyspozytorowi. Na 
          wymiany, dyspozytor może zażądać dokumentu potwierdzającego dokonanie płatności
          bilet biletowy
        </p>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  mounted() {
    this.$vuetify.goTo(0, 0);
  },
  computed: {
    ...mapGetters(["locale"]),
  },
};
</script>

<style>
</style>